import Cookies from "universal-cookie"
const cookies = new Cookies()

const newLocal = "X-MHMAPP-X-ACCESS-TOKEN"
export const token = cookies.get(newLocal)

export const authConfig = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
}

export const authConfigMultipart = {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
        'Accept': 'application/json',
    },
}