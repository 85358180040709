import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    resetPassword,
    modalReset,
} from "../../../../features/storage/admin/management/usersManagementSlice"
import { toast } from "react-toastify"
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
} from "mdb-react-ui-kit"

const initialFormData = {
    id: "",
    token: "",
    password: "",
    password_confirmation: "",
}

// Spinner breaks this modal component on loading

function UserResetPasswordModal(props) {
    const { id: userId, name: userName } = props.user
    const { modal } = useSelector((state) => state.usersManagement)
    const { id: modalId, isLoading, isSuccess, message, errorList } = modal
    const dispatch = useDispatch()
    const isCurrentModal = modalId === "user_reset_pw_" + parseInt(userId)
    const [basicModal, setBasicModal] = useState(isCurrentModal ? true : false)
    const toggleShow = () => setBasicModal(!basicModal)

    const [formData, setFormData] = useState(initialFormData)

    const { token, password, password_confirmation } = formData

    const onFormChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const payload = new FormData()
        payload.append("id", userId)
        payload.append("token", token)
        payload.append("password", password)
        payload.append("password_confirmation", password_confirmation)

        dispatch(resetPassword({ userId, payload }))
    }

    useEffect(() => {
        if (isCurrentModal && isSuccess) {
            toast.success(message)
            setFormData(initialFormData)
            setBasicModal(false)
            dispatch(modalReset())
        }
    }, [dispatch, isCurrentModal, isSuccess, message])

    return (
        <>
            <MDBBtn className="ms-1 me-1" size="sm" onClick={toggleShow}>
                Reset PW
            </MDBBtn>
            <MDBModal
                key={userId}
                show={basicModal}
                setShow={setBasicModal}
                staticBackdrop
                tabIndex="-1"
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Password Reset</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={toggleShow}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p className="mb-4">User: {userName}</p>
                            {isLoading ? "" : (
                            <MDBValidation
                                className={`row g-3`}
                                onSubmit={onSubmit}
                            >
                                <MDBValidationItem
                                    feedback={
                                        isCurrentModal && errorList.token
                                            ? errorList.token[0]
                                            : isCurrentModal && errorList.email
                                            ? errorList.email[0]
                                            : ""
                                    }
                                    invalid={
                                        isCurrentModal && errorList.token
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            isCurrentModal &&
                                            (errorList.token || errorList.email)
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="token"
                                        id={"token" + userId}
                                        label="Token"
                                        name="token"
                                        value={token}
                                        onChange={onFormChange}
                                        required
                                        readOnly={isLoading}
                                    />
                                </MDBValidationItem>
                                <MDBValidationItem
                                    feedback={
                                        isCurrentModal && errorList.password
                                            ? errorList.password[0]
                                            : ""
                                    }
                                    invalid={
                                        isCurrentModal && errorList.password
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            isCurrentModal &&
                                            errorList.password
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="password"
                                        id={"password" + userId}
                                        label="Password"
                                        name="password"
                                        value={password}
                                        onChange={onFormChange}
                                        readOnly={isLoading}
                                    />
                                </MDBValidationItem>
                                <MDBValidationItem
                                    feedback={
                                        isCurrentModal &&
                                        errorList.password_confirmation
                                            ? errorList.password_confirmation[0]
                                            : ""
                                    }
                                    invalid={
                                        isCurrentModal &&
                                        errorList.password_confirmation
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            isCurrentModal &&
                                            errorList.password_confirmation
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="password"
                                        id={"password_confirmation" + userId}
                                        label="Password Confirmation"
                                        name="password_confirmation"
                                        value={password_confirmation}
                                        onChange={onFormChange}
                                        required
                                        readOnly={isLoading}
                                    />
                                </MDBValidationItem>
                                <div className="col-12">
                                    <MDBBtn
                                        type="submit"
                                        block
                                        disabled={isLoading}
                                    >
                                        Save
                                    </MDBBtn>
                                </div>
                            </MDBValidation>
                            )}
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={toggleShow}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}

export default UserResetPasswordModal
