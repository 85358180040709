const apiErrorMessageParser = (error) => {
    let errorMessage;
    // console.log(error);
    // console.log(error.message);
    // console.log(error.response.data);
    // console.log(error.response.data.error);
    // console.log(error.response.status);

    errorMessage = error.message;

    if (error.response.status && error.response.status === 404) {
        if(['invalid_user', 'invalid_credentials'].includes(error.response?.data?.error)){
            errorMessage = error.response?.data?.message;
        }

        return errorMessage;
    }

    if(error.response.data && error.response.data.error.message){
        errorMessage = error.response.data.error.message;
        return errorMessage;
    }

    if (error.response.status && error.response.status === 400) {
        if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
            return errorMessage;
        }
    }

    // if (error.response.status && error.response.status === 401) {
    //     if (error.response.data && error.response.data.message) {
    //         errorMessage = error.response.data.message;
    //         return errorMessage;
    //     }
    // }

    return error.toString();
};

export default apiErrorMessageParser;
