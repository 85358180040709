import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getUsers, sendPasswordToken, reset } from "../../../../features/storage/admin/management/usersManagementSlice"
import { setErrorMessage, setSuccessMessage } from "../../../../features/messages/alertMessageSlice"
import Spinner from "../../../../components/Spinner"
import MainWrapper from "../../../../components/MainWrapper"
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBSelect,
    MDBInput,
    MDBValidationItem,
    MDBSpinner
} from "mdb-react-ui-kit"
import ReactPaginate from "react-paginate"
import AlertMessage from "../../../../components/AlertMessage"
import { useDebouncedCallback } from 'use-debounce'
import UserResetPasswordModal from "./UserResetPasswordModal"
import UserEditModal from "./UserEditModal"
import { Link } from "react-router-dom"

const initialSearchFormData = {
    searchUser: "",
}

function Users() {
    const {
        users,
        usersPagination,
        isLoading,
        isError,
        message,
        passwordTokenState
    } = useSelector((state) => state.usersManagement)
    const {
        id: passwordTokenId,
        isLoading: passwordTokenIsLoading,
        isSuccess: passwordTokenIsSuccess,
        isError: passwordTokenIsError,
        message: passwordTokenMessage,
    } = passwordTokenState
    const [currentPage, setCurrentPage] = useState(1)
    const [searchBy, setSearchBy] = useState()

    const dispatch = useDispatch()

    const searchByDataValues = () => {
        const searchByValues = ['id', 'name', 'username', 'email']

        const dataValues = searchByValues.map((value) => {
            return {
                text: value.charAt(0).toUpperCase()+ value.slice(1),
                value: value,
                selected: searchBy === value ? true : false,
            }
        })

        return [
            { text: "", value: undefined, selected: false },
            ...dataValues,
        ]
    }

    const [searchFormData, setSearchFormData] = useState(initialSearchFormData)
    const {
        searchUser,
    } = searchFormData

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1)
    }

    const debouncedOnSearchFormChange = useDebouncedCallback(
        (e) => {
            setSearchFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
            setCurrentPage(1)
        },
        // delay in ms
        1000
    );

    const onSearchByChange = (e) => {
        if (e !== undefined && e.value !== searchBy) {
            setSearchBy(e.value)
            setCurrentPage(1)
        }
    }

    const sendUserPasswordResetToken = (event, id) => {
        event.currentTarget.disabled = true
        dispatch(sendPasswordToken(id))
    }

    useEffect(() => {
        let queryParams = [
            { key: "page", value: currentPage }
        ]
        if(searchBy !== "" && searchUser !==""){
            queryParams = [
                ...queryParams,
                { key: "searchBy", value: searchBy },
                { key: "searchUser", value: searchUser },
            ]
        }
        dispatch(getUsers(queryParams))

        return () => {
            dispatch(reset())
        }
    }, [dispatch, currentPage, searchBy, searchUser])

    useEffect(() => {
        if (isError) {
            dispatch(setErrorMessage(message))
        }
    }, [dispatch, isError, message])

    useEffect(() => {
        if (passwordTokenIsError) {
            dispatch(setErrorMessage(passwordTokenMessage))
        }
        if (passwordTokenIsSuccess) {
            dispatch(setSuccessMessage(passwordTokenMessage))
        }
    }, [dispatch, passwordTokenIsError, passwordTokenIsSuccess, passwordTokenMessage])

    if (isLoading) {
        return <Spinner />
    }

    const UserRow = users.map(
        (user) => {
            return (
                <tr key={user.id}>
                    <th scope="row">{user.id}</th>
                    <td>{user?.auth_group?.name}</td>
                    <td>{user.name}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.activ}</td>
                    <td>
                        <MDBBtn size="sm" className="ms-1 me-1" onClick={(event) => sendUserPasswordResetToken(event, user.id)}>
                            {passwordTokenIsLoading && passwordTokenId === user.id? <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> : ""}
                            Get Token
                        </MDBBtn>
                        <UserResetPasswordModal key={`user_reset_pw_${user.id}`} user={user}></UserResetPasswordModal>
                        <UserEditModal key={`user_edit_${user.id}`} user={user}></UserEditModal>
                        <Link to={`/erp/storage/admin/management/user/${user.id}/reset-password`}></Link>
                    </td>
                </tr>
            )
        }
    )

    return (
        <MainWrapper>
            <MDBRow>
                <MDBCol>
                    <MDBCard className="m-3" border="primary">
                        <MDBCardHeader tag="h4">
                            Users
                        </MDBCardHeader>
                        <MDBCardBody>
                            <AlertMessage></AlertMessage>
                            <MDBRow>
                                <MDBCol size="2">
                                    <MDBValidationItem feedback={message ? message : ""} invalid={message ? true : false}>
                                        <MDBInput
                                            className={message ? "mb-5 is-invalid" : "mb-4"}
                                            type="text"
                                            id="searchUser"
                                            label={"Search"}
                                            name="searchUser"
                                            defaultValue={searchUser}
                                            onChange={debouncedOnSearchFormChange}
                                            required
                                        />
                                    </MDBValidationItem>
                                </MDBCol>
                                <MDBCol size="2">
                                    <MDBSelect
                                        className="mb-2"
                                        id="searchBy"
                                        label={searchBy === undefined ? "In?" : "In"}
                                        name="searchBy"
                                        data={searchByDataValues()}
                                        getValue={(e) => onSearchByChange(e)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBTable small>
                                <MDBTableHead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Group</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">isActive</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {UserRow}
                                </MDBTableBody>
                            </MDBTable>

                            <ReactPaginate
                                nextLabel="Next"
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                pageCount={
                                    usersPagination.last_page
                                }
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                onPageChange={handlePageChange}
                                forcePage={currentPage - 1}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination justify-content-center"
                                activeClassName="active"
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MainWrapper>
    )

}

export default Users