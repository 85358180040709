import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import alertMessageReducer from '../features/messages/alertMessageSlice'
import inventoryDocumentReducer from '../features/storage/inventory_documents/inventoryDocumentsSlice'
import usersManagementReducer from '../features/storage/admin/management/usersManagementSlice'
import operatorsTimeActivityReducer from '../features/storage/operators_time_activity/operatorsTimeActivitySlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alertMessage: alertMessageReducer,
    inventoryDocuments: inventoryDocumentReducer,
    usersManagement: usersManagementReducer,
    operatorsTimeActivity: operatorsTimeActivityReducer,
  },
})
