import React, { useState, useRef, useEffect } from "react"
import { MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBIcon, MDBRipple } from "mdb-react-ui-kit"
import { Link } from "react-router-dom"
import { BsClipboardData } from "react-icons/bs"

export default function Sidenav({ basicOpen, setBasicOpen }) {
    const [mode, setMode] = useState("side")
    const [backdrop, setBackdrop] = useState(false)
    const [collapseOpened, setCollapseOpened] = useState([])

    const editCollapseOpened = (newValues) => {
        let collapseOpenedCopy = [...collapseOpened]

        newValues.forEach((element) => {
            if (collapseOpenedCopy.includes(element)) {
                collapseOpenedCopy = collapseOpenedCopy.filter(function (value) {
                    return value !== element
                })
            } else {
                collapseOpenedCopy.push(element)
            }
        })

        return collapseOpenedCopy
    }

    const toggleAccordion = (newValues) => {
        const newColapse = editCollapseOpened(newValues)
        setCollapseOpened(newColapse)
    }

    const innerWidth = useRef(null)

    const checkResize = () => {
        if (window.innerWidth === innerWidth.current) {
            return
        }

        innerWidth.current = window.innerWidth

        if (window.innerWidth < 1400) {
            setMode("over")
            setBasicOpen(false)
            setBackdrop(true)
        } else {
            setMode("side")
            setBasicOpen(true)
            setBackdrop(false)
        }
    }

    useEffect(() => {
        checkResize()

        window.addEventListener("resize", checkResize)

        return () => {
            window.removeEventListener("resize", checkResize)
        }
    }, [])

    return (
        <>
            <MDBSideNav isOpen={basicOpen} backdrop={backdrop} getOpenState={(e) => setBasicOpen(e)} closeOnEsc={false}>
                <MDBRipple tag="a" className="d-flex justify-content-center py-4">
                    <img id="mhmr-logo" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/images/logo_hit.png`} alt="MHMR Logo" draggable="false" />
                </MDBRipple>
                <MDBSideNavMenu>
                    <MDBSideNavItem>
                        <Link to="/" className="sidenav-link">
                            <MDBIcon fas icon="home" className="fa-fw me-3" />
                            Home
                        </Link>
                    </MDBSideNavItem>
                    <MDBSideNavItem>
                        <Link to="/dashboard" className="sidenav-link">
                            <BsClipboardData className="fa-fw me-3"></BsClipboardData>
                            Dashboard
                        </Link>
                    </MDBSideNavItem>

                    <MDBSideNavItem>
                        <MDBSideNavLink
                            icon="angle-down"
                            shouldBeExpanded={collapseOpened.includes("storageCollapse")}
                            onClick={() => toggleAccordion(["storageCollapse"])}>
                            <MDBIcon fas icon="warehouse" className="fa-fw me-3" />
                            Storage
                        </MDBSideNavLink>
                    </MDBSideNavItem>

                    {collapseOpened.includes("storageCollapse") ? (
                        <>
                            <MDBSideNavItem className="ms-2">
                                <MDBSideNavLink
                                    icon="angle-down"
                                    shouldBeExpanded={collapseOpened.includes("storageDocsCollapse")}
                                    onClick={() => toggleAccordion(["storageDocsCollapse"])}>
                                    <MDBIcon fas icon="archive" className="fa-fw me-3" />
                                    Documents
                                </MDBSideNavLink>
                                <MDBSideNavCollapse id="storageDocsCollapse" show={collapseOpened.includes("storageDocsCollapse")}>
                                    <Link to="/erp/storage/documents" className="sidenav-link ms-4">
                                        List
                                    </Link>
                                    <Link to="/erp/storage/documents/create" className="sidenav-link ms-4">
                                        Create
                                    </Link>
                                </MDBSideNavCollapse>
                            </MDBSideNavItem>

                            <MDBSideNavItem className="ms-2">
                                <MDBSideNavLink
                                    icon="angle-down"
                                    shouldBeExpanded={collapseOpened.includes("storageOperatorsActivityCollapse")}
                                    onClick={() => toggleAccordion(["storageOperatorsActivityCollapse"])}>
                                    <MDBIcon fas icon="archive" className="fa-fw me-3" />
                                    Operators Activity
                                </MDBSideNavLink>
                                <MDBSideNavCollapse id="storageOperatorsActivityCollapse" show={collapseOpened.includes("storageOperatorsActivityCollapse")}>
                                    <Link to="/erp/storage/operator-time-activity" className="sidenav-link ms-4">
                                        List
                                    </Link>
                                    <Link to="/erp/storage/operator-time-activity/create" className="sidenav-link ms-4">
                                        Create
                                    </Link>
                                </MDBSideNavCollapse>
                            </MDBSideNavItem>
                        </>
                    ) : (
                        <></>
                    )}

                    <MDBSideNavItem>
                        <MDBSideNavLink
                            icon="angle-down"
                            shouldBeExpanded={collapseOpened.includes("userCollapse")}
                            onClick={() => toggleAccordion(["userCollapse"])}>
                            <MDBIcon fas icon="cogs" className="fa-fw me-3" />
                            User Management
                        </MDBSideNavLink>
                        <MDBSideNavCollapse id="userCollapse" show={collapseOpened.includes("userCollapse")}>
                            <Link to="/erp/storage/admin/management/users" className="sidenav-link">
                                Users
                            </Link>
                            <Link className="sidenav-link" to="/forgot-password">
                                Forgot password page
                            </Link>
                        </MDBSideNavCollapse>
                    </MDBSideNavItem>
                </MDBSideNavMenu>
            </MDBSideNav>
        </>
    )
}
