import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
// import "mdb-react-file-upload/dist/css/file-upload.min.css"
import './index.css'
import NotFound404 from './pages/NotFound404'
import VersionCheck from './pages/VersionCheck'
import Header from './components/Header'
import ProtectedRoutesGroup from './components/ProtectedRoutesGroup'
import ProtectedRoute from './components/ProtectedRoute'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Register from './pages/Register'
import ChangePassword from './pages/ChangePassword'
import Users from './pages/storage/admin/management/Users'
import InventoryDocuments from './pages/storage/inventory_documents/InventoryDocuments'
import NewInventoryDocument from './pages/storage/inventory_documents/NewInventoryDocument'
import ShowInventoryDocument from './pages/storage/inventory_documents/ShowInventoryDocument'
import UserResetPassword from './pages/storage/admin/management/UserResetPassword'
import OperatorTimeActivities from './pages/storage/operators_time_activity/OperatorTimeActivities'
import NewOperatorTimeActivity from './pages/storage/operators_time_activity/NewOperatorTimeActivity'

function App() {
  return (
    <>
      <Router>
        <Header />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/dashboard' element={<ProtectedRoutesGroup />}>
                <Route path='/dashboard' element={<Dashboard />} />
              </Route>
              <Route path='/login' element={<Login />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/change-password/:token/:email' element={<ChangePassword />} />
              <Route path='/register' element={<Register />} />


              <Route path='/erp'>
                <Route path='/erp/storage'>
                  <Route path='/erp/storage/documents' element={<ProtectedRoutesGroup allowedRules={['godlike', 'ecommerce_admin']} allowedIds={[]}/>}>
                    <Route path='/erp/storage/documents' element={<InventoryDocuments />} />
                    <Route path='/erp/storage/documents/:id' element={<ShowInventoryDocument />} />
                    <Route path='/erp/storage/documents/create' element={<NewInventoryDocument />} />
                  </Route>

                  <Route path='/erp/storage/operator-time-activity' element={<ProtectedRoutesGroup allowedRules={['godlike', 'pontaje_acces', 'pontaje_superowner']} allowedIds={[]}/>}>
                    <Route path='/erp/storage/operator-time-activity' element={<OperatorTimeActivities />} />
                    <Route path='/erp/storage/operator-time-activity/create' element={<NewOperatorTimeActivity />} />
                  </Route>
                </Route>

                <Route path='/erp/storage/admin' element={<ProtectedRoutesGroup allowedRules={['godlike', 'ecommerce_admin']} allowedIds={[]}/>}>
                  <Route path='/erp/storage/admin/management/users' element={<Users />} />
                  <Route path='/erp/storage/admin/management/user/:id/reset-password' element={<UserResetPassword />} />
                </Route>
              </Route>

              <Route path='/test' element={<ProtectedRoutesGroup allowedRules={[]} allowedIds={[1224]}/>}>
                <Route path='/test/1' element={<VersionCheck />} />
                <Route path='/test/2' element={<VersionCheck />} />
              </Route>

              <Route path='/superAdmin' element={<ProtectedRoutesGroup allowedRules={[]} allowedIds={[1224]}/>}>
                <Route path='/superAdmin/1' element={<ProtectedRoute allowedIds={[1223]}><VersionCheck /></ProtectedRoute>} />
                <Route path='/superAdmin/2' element={<ProtectedRoute allowedIds={[1224]}><VersionCheck /></ProtectedRoute>} />
              </Route>

              <Route path="/v-check" element={<ProtectedRoute allowedRules={['godlike']} allowedIds={['1224']}><VersionCheck /></ProtectedRoute>} />
              <Route path="*" element={<NotFound404 />} />
            </Routes>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
