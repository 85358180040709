import loggerAxios from "./../../utils/loggerAxios"
import Cookies from "universal-cookie"

const BASE_URL = loggerAxios.defaults.baseUrl
const API_URL = BASE_URL + "auth/"
const cookies = new Cookies()

// Register user
const register = async (userData) => {
    const response = await loggerAxios.post(API_URL, userData)

    return response.data
}

// Login user
const login = async (userData) => {
    const body = new URLSearchParams()
    body.append('username', userData.email)
    body.append('password', userData.password)
    body.append('rememberMe', userData.rememberMe === true ? 'yes' : 'no')
    body.append('client_id', process.env.REACT_APP_CLIENT_ID)

    const responseLogin = await loggerAxios.post(
        API_URL + "login",
        body, 
        {
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
    )

    if (responseLogin.data) {
        const token = responseLogin.data.access_token

        let date = new Date()
        date.setTime(date.getTime() + responseLogin.data.expires_in * 1000)
        const cookieOptions = {
            path: "/",
            expires: date,
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            secure: true,
            sameSite: 'strict',
        }
        cookies.set("X-MHMAPP-X-ACCESS-TOKEN", token, cookieOptions)

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        const response = await loggerAxios.get(
            loggerAxios.defaults.baseUrl + "user",
            config
        )

        if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data))
        }

        return response.data
    }

    return responseLogin.data
}

// Logout user
const logout = async () => {
    // localStorage.removeItem("user")
    // cookies.remove('X-MHMAPP-X-ACCESS-TOKEN')
    const token = cookies.get("X-MHMAPP-X-ACCESS-TOKEN")
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const responseLogout = await loggerAxios.post(
        API_URL + "logout",
        {},
        config
    )

    if (responseLogout.data) {
        localStorage.removeItem("user")
        cookies.remove("X-MHMAPP-X-ACCESS-TOKEN")
    }

    return responseLogout.data
}

// Forgot Password / send email
const forgotPassword = async (userData) => {
    const response = await loggerAxios.post(API_URL + "forgot-password", userData)

    // if (response.data) {
    // }
    return response.data
}

// Change Password using token
const changePassword = async (userData) => {
    const response = await loggerAxios.post(API_URL + "reset-password", userData)

    return response.data
}

const authService = {
    register,
    logout,
    login,
    forgotPassword,
    changePassword,
}

export default authService
