import loggerAxios  from "./../../../utils/loggerAxios"
import { authConfig, authConfigMultipart } from "../../../utils/authorizationUtils"

const BASE_URL = loggerAxios.defaults.baseUrl
const API_URL = BASE_URL + "erp/storage/operator-time-activity"

// get activities
const getOperatorTimeActivities = async (queryParams) => {
    let queryString = queryParams.reduce(function(queryString, param) {
        if(param.value !== undefined && param.value !== ""){
            queryString += param.key + "=" + param.value + "&"
        }
        return queryString;
    }, '')
    const response = await loggerAxios.get(
        API_URL + "?" + queryString.slice(0, -1),
        authConfig
    )

    return response.data
}

// Create new activity
const createActivity = async (data) => {
    const response = await loggerAxios.post(API_URL, data, authConfig)

    return response.data
}

// Delete activity
const deleteActivity = async (activityId) => {
    const response = await loggerAxios.delete(API_URL + '/' + activityId, authConfig)

    return response.data
}

const calculate = async (data) => {
    const response = await loggerAxios.post(API_URL + '/calculate', data, authConfig)

    return response.data
}

const dateHasSharedActivity = async (data) => {
    const response = await loggerAxios.post(API_URL + '/dateHasSharedActivity', data, authConfig)

    return response.data
}

const operatorsTimeActivityService = {
    getOperatorTimeActivities,
    createActivity,
    deleteActivity,
    calculate,
    dateHasSharedActivity
}

export default operatorsTimeActivityService