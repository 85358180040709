import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    updateUser,
    modalReset,
} from "../../../../features/storage/admin/management/usersManagementSlice"
import { toast } from "react-toastify"
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
} from "mdb-react-ui-kit"

// Spinner breaks this modal component on loading

// const initialFormData = {
//     email: "",
//     username: "",
//     pin: "",
// }

function UserEditModal(props) {
    const { id: userId } = props.user
    const { modal } = useSelector((state) => state.usersManagement)
    const { id: modalId, isLoading, isSuccess, message, errorList } = modal
    const dispatch = useDispatch()
    const isCurrentModal = modalId === "user_edit_" + parseInt(userId)
    const [basicModal, setBasicModal] = useState(isCurrentModal ? true : false)
    const toggleShow = () => setBasicModal(!basicModal)

    const [formData, setFormData] = useState({
        email: props.user.email,
        username: props.user.username,
        pin: props.user.pin,
    })

    const { email, username, pin } = formData

    const onFormChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const payload = new FormData()
        payload.append("email", email)
        payload.append("username", username)
        payload.append("pin", pin)
        payload.append('_method', 'PATCH');

        dispatch(updateUser({ userId, payload }))
    }

    useEffect(() => {
        if (isCurrentModal && isSuccess) {
            toast.success(message)
            setBasicModal(false)
            dispatch(modalReset())
        }
    }, [dispatch, isCurrentModal, isSuccess, message])

    return (
        <>
            <MDBBtn className="ms-1 me-1" size="sm" onClick={toggleShow}>
                Edit
            </MDBBtn>
            <MDBModal
                key={userId}
                show={basicModal}
                setShow={setBasicModal}
                staticBackdrop
                tabIndex="-1"
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Edit User</MDBModalTitle>
                            <MDBBtn
                                className="btn-close"
                                color="none"
                                onClick={toggleShow}
                            ></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p className="mb-4">User: {props.user.name}</p>
                            {isLoading ? "" : (
                            <MDBValidation
                                className={`row g-3`}
                                onSubmit={onSubmit}
                            >
                                <MDBValidationItem
                                    feedback={
                                        isCurrentModal && errorList.email
                                            ? errorList.email[0]
                                            : ""
                                    }
                                    invalid={
                                        isCurrentModal && errorList.email
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            isCurrentModal &&
                                            errorList.email
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="email"
                                        id={"email" + userId}
                                        label="Email"
                                        name="email"
                                        value={email}
                                        onChange={onFormChange}
                                        readOnly={isLoading}
                                    />
                                </MDBValidationItem>
                                <MDBValidationItem
                                    feedback={
                                        isCurrentModal && errorList.username
                                            ? errorList.username[0]
                                            : ""
                                    }
                                    invalid={
                                        isCurrentModal && errorList.username
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            isCurrentModal &&
                                            errorList.username
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="text"
                                        id={"username" + userId}
                                        label="Username"
                                        name="username"
                                        value={username}
                                        onChange={onFormChange}
                                        required
                                        readOnly={isLoading}
                                    />
                                </MDBValidationItem>
                                <MDBValidationItem
                                    feedback={
                                        isCurrentModal &&
                                        errorList.pin
                                            ? errorList.pin[0]
                                            : ""
                                    }
                                    invalid={
                                        isCurrentModal &&
                                        errorList.pin
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            isCurrentModal &&
                                            errorList.pin
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="number"
                                        id={"pin" + userId}
                                        label="Pin"
                                        name="pin"
                                        value={pin}
                                        onChange={onFormChange}
                                        required
                                        readOnly={isLoading}
                                        min="1000"
                                        max="9999"
                                    />
                                </MDBValidationItem>
                                <div className="col-12">
                                    <MDBBtn
                                        type="submit"
                                        block
                                        disabled={isLoading}
                                    >
                                        Save
                                    </MDBBtn>
                                </div>
                            </MDBValidation>
                            )}
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={toggleShow}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}

export default UserEditModal
