/**
 * https://mdbootstrap.com/support/react/reset-mdbselect/
 */
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBInput,
    MDBRow,
    MDBSelect,
    MDBValidation,
    MDBValidationItem,
    MDBIcon,
    MDBAlert,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from "mdb-react-ui-kit"
import Select from "react-select"
import AlertMessage from "../../../components/AlertMessage"
import { useEffect, useState, useRef, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import MainWrapper from "../../../components/MainWrapper"
import Spinner from "../../../components/Spinner"
import {
    getStorageUsers,
    getProjects,
    createActivity,
    deleteActivity,
    calculateActivity,
    dateHasSharedActivity,
    stateReset,
    successReset,
    reset,
} from "../../../features/storage/operators_time_activity/operatorsTimeActivitySlice"

const initialFormData = {
    date: "",
    operatorId: "",
    projectId: "",
    hours: "",
    activityDescription: "",
}

function NewOperatorTimeActivity() {
    const { storageUsers, projects, currentActivities, hasSharedActivity, isLoading, isError, isSuccess, isDeleted, message, errorList } = useSelector(
        (state) => state.operatorsTimeActivity
    )
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(initialFormData)

    const { date, operatorId, operatorSelectedOption, projectId, projectSelectedOption, hours, activityDescription } = formData
    const [selectProjectDisabled, setSelectProjectDisabled] = useState(false)
    const [selectProjectLocked, setSelectProjectLocked] = useState(false)
    const [selectUserLocked, setSelectUserLocked] = useState(false)

    const [selectUserOptions, setSelectUserOptions] = useState([])
    const mappedUsers = useCallback(() => {
        const users = storageUsers.map((storageUser) => {
            return {
                text: storageUser.name,
                value: storageUser.id,
                label: storageUser.name,
                selected: operatorId === storageUser.id ? true : false,
            }
        })

        return [{ text: "", value: undefined, label: "", defaultSelected: false }, ...users]
    }, [storageUsers, operatorId])

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const mappedProjects = useCallback(() => {
        const arr = projects.map((project) => {
            return {
                text: project.projectIdentifier,
                value: project.id,
                label: project.projectIdentifier,
                selected: projectId === project.id ? true : false,
            }
        })

        return [{ text: "", value: undefined, label: "", defaultSelected: false }, ...arr]
    }, [projects, projectId])

    const onFormChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
        if (e.target.name === "date") {
            focus("operator")
        }
    }

    const setUserSelectedOption = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            operatorId: e.value,
            operatorSelectedOption: e,
        }))
    }

    const setProjectSelectedOption = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            projectId: e.value,
            projectSelectedOption: e,
        }))
    }

    const selectOperatorRef = useRef(null)
    const selectProjectRef = useRef(null)
    // Focus handlers
    const focus = (target) => {
        if (target === "operator") {
            selectOperatorRef.current?.focus()
        }
        if (target === "project") {
            selectProjectRef.current?.focus()
        }
    }

    useEffect(() => {
        dispatch(getStorageUsers())
        dispatch(getProjects())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    useEffect(() => {
        setSelectProjectOptions(mappedProjects())
    }, [mappedProjects, projectId])

    useEffect(() => {
        setSelectUserOptions(mappedUsers())
    }, [mappedUsers, operatorId])

    useEffect(() => {
        if (date && operatorId) {
            const payload = new FormData()
            payload.append("operatorId", operatorId)
            payload.append("dateStart", date)
            payload.append("dateEnd", date)

            dispatch(calculateActivity(payload))
            dispatch(dateHasSharedActivity({ date: date }))
        }

        return () => {
            dispatch(stateReset())
        }
    }, [dispatch, date, operatorId, isSuccess, isDeleted])

    useEffect(() => {
        if (hasSharedActivity === true) {
            setSelectProjectDisabled(true)
        } else {
            setSelectProjectDisabled(false)
        }
    }, [hasSharedActivity])

    const onSubmit = (e) => {
        e.preventDefault()

        const payload = new FormData()
        payload.append("date", date)
        payload.append("operatorId", operatorId)
        payload.append("projectId", projectId)
        payload.append("actingAs", "Operator")
        payload.append("hours", hours)
        payload.append("activityDescription", activityDescription)

        dispatch(createActivity(payload))
    }

    useEffect(() => {
        if (isSuccess) {
            toast.success("Activity added!")

            setFormData((prevState) => ({
                ...prevState,
                hours: "",
                activityDescription: "",
                operatorId: selectUserLocked === false ? "" : operatorId,
                operatorSelectedOption: selectUserLocked === false ? null : operatorSelectedOption,
                projectId: selectProjectLocked === false ? "" : projectId,
                projectSelectedOption: selectProjectLocked === false ? null : projectSelectedOption,
            }))
        }

        return () => {
            dispatch(successReset())
        }
    }, [isError, dispatch, isSuccess, message])

    if (isLoading) {
        return <Spinner />
    }

    const activitiesTableRow = currentActivities?.list?.map((activity) => {
        const createdAt = new Date(activity.createdAt).toLocaleString("en-GB")
        return (
            <tr key={activity.id}>
                <th scope="row">{activity.id}</th>
                <td>{activity.operator.name}</td>
                <td>{activity.project.projectIdentifier}</td>
                <td>{activity.shortTimeFormat}</td>
                <td>{activity.activityDescription}</td>
                <td>{createdAt}</td>
                <td>
                    <MDBBtn size="sm" color="danger" onClick={() => dispatch(deleteActivity(activity.id))}>
                        Delete
                    </MDBBtn>
                </td>
            </tr>
        )
    })

    return (
        <>
            <MainWrapper>
                <MDBRow>
                    <MDBCol>
                        <MDBCard className="m-5" border="primary">
                            <MDBCardHeader tag="h4">New operator activity</MDBCardHeader>
                            <MDBCardBody>
                                <AlertMessage></AlertMessage>
                                <MDBValidation encType="multipart/form-data" onSubmit={onSubmit}>
                                    <MDBRow>
                                        <MDBCol size="3">
                                            <MDBValidationItem feedback={errorList.date ? errorList.date[0] : ""} invalid={errorList.date ? true : false}>
                                                <MDBInput
                                                    className={errorList.date ? "mb-5 is-invalid" : "mb-4"}
                                                    type="date"
                                                    id="date"
                                                    label="Date"
                                                    name="date"
                                                    value={date}
                                                    onChange={onFormChange}
                                                    required
                                                />
                                            </MDBValidationItem>
                                        </MDBCol>
                                        <MDBCol size="3">
                                            <Select
                                                defaultValue={operatorSelectedOption}
                                                options={selectUserOptions}
                                                onChange={setUserSelectedOption}
                                                ref={selectOperatorRef}
                                                autoFocus={(date !== "" && projectId === "") || (date !== "" && operatorId === "" && projectId !== "")}
                                            />
                                            {errorList.operatorId ? <p className="form-text small text-danger mb-2">{errorList.operatorId[0]}</p> : <></>}
                                        </MDBCol>
                                        <MDBBtn
                                            tag="a"
                                            targeting="operatorId"
                                            floating
                                            outline
                                            color={selectUserLocked ? "danger" : "success"}
                                            onClick={() => {
                                                setSelectUserLocked(!selectUserLocked)
                                                focus("operator")
                                            }}>
                                            <MDBIcon fas size="2x" icon={selectUserLocked ? "lock" : "unlock"} />
                                        </MDBBtn>
                                        <MDBCol size="3">
                                            <Select
                                                defaultValue={projectSelectedOption}
                                                options={selectProjectOptions}
                                                onChange={setProjectSelectedOption}
                                                ref={selectProjectRef}
                                                autoFocus={date !== "" && operatorId !== "" && projectId === ""}
                                                isDisabled={selectProjectDisabled}
                                            />
                                            {errorList.projectId ? <p className="form-text small text-danger mb-2">{errorList.projectId[0]}</p> : <></>}
                                        </MDBCol>
                                        <MDBBtn
                                            tag="a"
                                            targeting="projectId"
                                            floating
                                            outline
                                            color={selectProjectLocked ? "danger" : "success"}
                                            onClick={() => {
                                                setSelectProjectLocked(!selectProjectLocked)
                                                focus("project")
                                            }}>
                                            <MDBIcon fas size="2x" icon={selectProjectLocked ? "lock" : "unlock"} />
                                        </MDBBtn>
                                    </MDBRow>

                                    {date !== "" && operatorId ? (
                                        <>
                                            <MDBRow>
                                                <MDBCol size="6">
                                                    <p>
                                                        Operator Activity: <strong>{currentActivities?.total?.timeFormat}</strong>
                                                    </p>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol size="4">
                                                    <MDBValidationItem
                                                        feedback={errorList.hours ? errorList.hours[0] : ""}
                                                        invalid={errorList.hours ? true : false}>
                                                        <MDBInput
                                                            className={errorList.hours ? "mb-5 is-invalid" : "mb-4"}
                                                            type="number"
                                                            id="hours"
                                                            label="Hours"
                                                            name="hours"
                                                            value={hours}
                                                            onChange={onFormChange}
                                                            required
                                                            autoFocus={date !== "" && operatorId !== "" && projectId !== ""}
                                                            step="0.5"
                                                            min="0.5"
                                                        />
                                                    </MDBValidationItem>
                                                </MDBCol>
                                                <MDBCol size="4">
                                                    <MDBValidationItem
                                                        feedback={errorList.activityDescription ? errorList.activityDescription[0] : ""}
                                                        invalid={errorList.activityDescription ? true : false}>
                                                        <MDBInput
                                                            className={errorList.activityDescription ? "mb-5 is-invalid" : "mb-4"}
                                                            type="text"
                                                            id="activityDescription"
                                                            label="Activity description"
                                                            name="activityDescription"
                                                            value={activityDescription}
                                                            onChange={onFormChange}
                                                            required
                                                        />
                                                    </MDBValidationItem>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBRow>
                                                <MDBCol>
                                                    <MDBAlert show className="w-100" color="warning">
                                                        <i className="fas fa-exclamation-triangle me-3"></i>
                                                        <small>
                                                            If the activity already exists it will be modified with the new filled value! Ex: If Operator 'B'
                                                            worked 5h for project 'X' and you try to add 3 more hours you need to input 8h.
                                                        </small>
                                                    </MDBAlert>
                                                </MDBCol>
                                            </MDBRow>

                                            <MDBBtn type="submit" block>
                                                Save
                                            </MDBBtn>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </MDBValidation>

                                {typeof activitiesTableRow !== "undefined" && activitiesTableRow.length > 0 ? (
                                    <MDBRow className="mt-3">
                                        <MDBCol>
                                            <MDBCardHeader tag="h4">Activities</MDBCardHeader>
                                            <MDBTable small>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th scope="col">Id</th>
                                                        <th scope="col">Operator</th>
                                                        <th scope="col">Project ID</th>
                                                        <th scope="col">Time</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">CreatedAt</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>{activitiesTableRow}</MDBTableBody>
                                            </MDBTable>
                                        </MDBCol>
                                    </MDBRow>
                                ) : (
                                    <></>
                                )}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MainWrapper>
        </>
    )
}

export default NewOperatorTimeActivity
