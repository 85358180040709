import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    resetPassword,
    modalReset,
} from "../../../../features/storage/admin/management/usersManagementSlice"
import { toast } from "react-toastify"
import {
    MDBBtn,
    MDBInput,
    MDBValidation,
    MDBValidationItem,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
} from "mdb-react-ui-kit"
import { useParams } from "react-router-dom"
import MainWrapper from "../../../../components/MainWrapper"
import Spinner from "../../../../components/Spinner"

const initialFormData = {
    id: "",
    token: "",
    password: "",
    password_confirmation: "",
}

// Spinner breaks this modal component on loading

function UserResetPassword() {
    const params = useParams()
    const { id: userId, id: userName } = params
    const { modal } = useSelector((state) => state.usersManagement)
    const { isLoading, isSuccess, message, errorList } = modal
    const dispatch = useDispatch()

    const [formData, setFormData] = useState(initialFormData)

    const { token, password, password_confirmation } = formData

    const onFormChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const payload = new FormData()
        payload.append("id", userId)
        payload.append("token", token)
        payload.append("password", password)
        payload.append("password_confirmation", password_confirmation)

        dispatch(resetPassword({ userId, payload }))
    }

    useEffect(() => {
        if (isSuccess) {
            toast.success(message)
            setFormData(initialFormData)
            dispatch(modalReset())
        }
    }, [dispatch, isSuccess, message])

    if (isLoading) {
        return <Spinner />
    }

    return (
        <>
        <MainWrapper>
            <MDBRow>
                <MDBCol>
                    <MDBCard className="m-5" border="primary">
                        <MDBCardHeader tag="h4">
                            Reset Password
                        </MDBCardHeader>
                        <MDBCardBody>
                            <p className="mb-4">User: {userName}</p>
                            <MDBValidation
                                encType="multipart/form-data"
                                onSubmit={onSubmit}
                                noValidate={false}
                            >
                                <MDBValidationItem
                                    feedback={
                                        errorList.token
                                            ? errorList.token[0]
                                            : errorList.email
                                            ? errorList.email[0]
                                            : ""
                                    }
                                    invalid={
                                        errorList.token
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            
                                            (errorList.token || errorList.email)
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="token"
                                        id={"token" + userId}
                                        label="Token"
                                        name="token"
                                        value={token}
                                        onChange={onFormChange}
                                        readOnly={isLoading}
                                    />
                                </MDBValidationItem>
                                <MDBValidationItem feedback={errorList.password ? errorList.password[0] : ""} invalid={errorList.password ? true : false}>
                                        <MDBInput
                                            className={errorList.password ? "mb-5 is-invalid" : "mb-4"}
                                            type="text"
                                            id="doc_no"
                                            label={"Password"}
                                            name="password"
                                            value={password}
                                            onChange={onFormChange}
                                        />
                                        </MDBValidationItem>
                                <MDBValidationItem
                                    feedback={
                                        
                                        errorList.password_confirmation
                                            ? errorList.password_confirmation[0]
                                            : ""
                                    }
                                    invalid={
                                        
                                        errorList.password_confirmation
                                            ? true
                                            : false
                                    }
                                >
                                    <MDBInput
                                        className={
                                            
                                            errorList.password_confirmation
                                                ? "mb-5 is-invalid"
                                                : "mb-4"
                                        }
                                        type="password"
                                        id={"password_confirmation" + userId}
                                        label="Password Confirmation"
                                        name="password_confirmation"
                                        value={password_confirmation}
                                        onChange={onFormChange}
                                        required
                                        readOnly={isLoading}
                                    />
                                </MDBValidationItem>
                                <div className="col-12">
                                    <MDBBtn
                                        type="submit"
                                        block
                                        disabled={isLoading}
                                    >
                                        Save
                                    </MDBBtn>
                                </div>
                            </MDBValidation>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MainWrapper>
        </>
    )
}

export default UserResetPassword
