import loggerAxios from "./../../../utils/loggerAxios"
import { authConfig } from "../../../utils/authorizationUtils"

const BASE_URL = loggerAxios.defaults.baseUrl
const API_URL = BASE_URL + "erp/projects"

// get ecom clients
const getProjects = async () => {
    const response = await loggerAxios.get(
        API_URL,
        authConfig
    )

    return response.data
}

const projectsService = {
    getProjects,
}

export default projectsService