import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import usersManagementService from "./usersManagementService"
import apiErrorMessageParser from "../../../../utils/apiErrorParser"

const initialState = {
    users: [],
    usersPagination: {
        current_page: 1,
        last_page: 1,
        per_page: 0,
    },
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    errorList: [],

    passwordTokenState: {
        id: 0,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
    },

    modal: {
        id: 0,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
        errorList: [],
    },
}

// Get all users paginated
export const getUsers = createAsyncThunk(
    "users/getUsers",
    async (queryParams, thunkAPI) => {
        try {
            return await usersManagementService.getUsers(queryParams)
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Send Password Token
export const sendPasswordToken = createAsyncThunk(
    "users/sendPasswordToken",
    async (id, thunkAPI) => {
        try {
            return await usersManagementService.sendPasswordToken(id)
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Change Password
export const resetPassword = createAsyncThunk(
    "users/resetPassword",
    async (data, thunkAPI) => {
        const { payload } = data
        try {
            return await usersManagementService.resetPassword(payload)
        } catch (error) {
            if (error.response.data.errors) {
                return thunkAPI.rejectWithValue(error.response.data)
            }
            const message = apiErrorMessageParser(error)
            return thunkAPI.rejectWithValue(message)
        }
    },
    {}
)

// Update user
export const updateUser = createAsyncThunk(
    "users/updateUser",
    async (data, thunkAPI) => {
        const { userId, payload } = data
        try {
            return await usersManagementService.updateUser(userId, payload)
        } catch (error) {
            if (error.response.data.errors) {
                return thunkAPI.rejectWithValue(error.response.data)
            }
            const message = apiErrorMessageParser(error)
            return thunkAPI.rejectWithValue(message)
        }
    },
    {}
)

export const usersManagementSlice = createSlice({
    name: "usersManagement",
    initialState,
    reducers: {
        reset: (state) => initialState,
        modalReset: (state) => {
            state.isError = false
            state.isSuccess = false
            state.isLoading = false
            state.modal.id = 0
            state.modal.isError = false
            state.modal.isSuccess = false
            state.modal.isLoading = false
            state.modal.message = ""
            state.modal.errorList = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.modal.id = 0
                state.isLoading = true
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.users = Object.values(action.payload.data)
                state.usersPagination = {
                    current_page: action.payload.current_page,
                    last_page: action.payload.last_page,
                    per_page: action.payload.per_page,
                }
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(sendPasswordToken.pending, (state, action) => {
                state.passwordTokenState.id = action.meta.arg
                state.passwordTokenState.isLoading = true
            })
            .addCase(sendPasswordToken.fulfilled, (state, action) => {
                state.passwordTokenState.isLoading = false
                state.passwordTokenState.isError = false
                state.passwordTokenState.isSuccess = true
                state.passwordTokenState.message = action.payload.message
            })
            .addCase(sendPasswordToken.rejected, (state, action) => {
                state.passwordTokenState.isLoading = false
                state.passwordTokenState.isError = true
                state.passwordTokenState.isSuccess = false
                state.passwordTokenState.message = action.payload
            })
            .addCase(resetPassword.pending, (state, action) => {
                state.modal.id = "user_reset_pw_" + parseInt(action.meta.arg.userId)
                state.modal.isLoading = true
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.modal.id = "user_reset_pw_" + parseInt(action.meta.arg.userId)
                state.modal.isLoading = false
                state.modal.isError = false
                state.modal.isSuccess = true
                state.modal.message = action.payload.message
                state.modal.errorList = []
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.modal.id = "user_reset_pw_" + parseInt(action.meta.arg.userId)
                state.modal.isLoading = false
                state.modal.isError = true
                state.modal.isSuccess = false
                state.modal.message = action.payload.message
                state.modal.errorList = action.payload.errors ?? []
            })
            .addCase(updateUser.pending, (state, action) => {
                state.modal.id = "user_edit_" + parseInt(action.meta.arg.userId)
                state.modal.isLoading = true
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.modal.id = "user_edit_" + parseInt(action.meta.arg.userId)
                state.modal.isLoading = false
                state.modal.isError = false
                state.modal.isSuccess = true
                state.modal.message = "User updated succesfully."
                state.modal.errorList = []

                state.users = state.users.map(obj => {
                    if (obj.id === action.payload.id) {
                        return {
                            ...obj, 
                            username: action.payload.username,
                            email: action.payload.email,
                            pin: action.payload.pin,
                        };
                    }
                    // 👇️ otherwise return the object as is
                    return obj;
                });
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.modal.id = "user_edit_" + parseInt(action.meta.arg.userId)
                state.modal.isLoading = false
                state.modal.isError = true
                state.modal.isSuccess = false
                state.modal.message = action.payload.message
                state.modal.errorList = action.payload.errors ?? []
            })
    },
})

export const { reset, modalReset } = usersManagementSlice.actions
export default usersManagementSlice.reducer
