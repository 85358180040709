import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import operatorsTimeActivityService from "./operatorsTimeActivityService"
import usersService from "../../users/usersService"
import projectsService from "../../ERP/projects/projectsService"
import apiErrorMessageParser from "../../../utils/apiErrorParser"

const initialState = {
    storageUsers: [],
    projects: [],
    currentActivities: [],
    hasSharedActivity: false,
    activities: [],
    activitiesPagination: {
        current_page: 1,
        last_page: 1,
        per_page: 0,
    },
    // inventoryDocument: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    pendingDeletedActivityId: 0,
    isDeleted: false,
    message: "",
    errorList: [],
}

export const getStorageUsers = createAsyncThunk(
    "users/getStorageUsers",
    async (_, thunkAPI) => {
        try {
            return await usersService.getStorageUsers()
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getProjects = createAsyncThunk(
    "projects/getProjects",
    async (_, thunkAPI) => {
        try {
            return await projectsService.getProjects()
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get all activities
export const getOperatorTimeActivities = createAsyncThunk(
    "operatorsTimeActivity/getOperatorTimeActivities",
    async (queryParams, thunkAPI) => {
        try {
            return await operatorsTimeActivityService.getOperatorTimeActivities(queryParams)
        } catch (error) {
            const message = apiErrorMessageParser(error)

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Create new activity
export const createActivity = createAsyncThunk(
    "operatorsTimeActivity/create",
    async (data, thunkAPI) => {
        try {
            return await operatorsTimeActivityService.createActivity(data)
        } catch (error) {
            const message = apiErrorMessageParser(error)
            if (error.response.data.errors) {
                return thunkAPI.rejectWithValue(error.response.data)
            }

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete activity
export const deleteActivity = createAsyncThunk(
    "operatorsTimeActivity/delete",
    async (activityId, thunkAPI) => {
        try {
            return await operatorsTimeActivityService.deleteActivity(activityId)
        } catch (error) {
            const message = apiErrorMessageParser(error)
            if (error.response.data.errors) {
                return thunkAPI.rejectWithValue(error.response.data)
            }

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Calculate activity
export const calculateActivity = createAsyncThunk(
    "operatorsTimeActivity/calculate",
    async (data, thunkAPI) => {
        try {
            return await operatorsTimeActivityService.calculate(data)
        } catch (error) {
            const message = apiErrorMessageParser(error)
            if (error.response.data.errors) {
                return thunkAPI.rejectWithValue(error.response.data)
            }

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Check if date has shared activity
export const dateHasSharedActivity = createAsyncThunk(
    "operatorsTimeActivity/dateHasSharedActivity",
    async (data, thunkAPI) => {
        try {
            return await operatorsTimeActivityService.dateHasSharedActivity(data)
        } catch (error) {
            const message = apiErrorMessageParser(error)
            if (error.response.data.errors) {
                return thunkAPI.rejectWithValue(error.response.data)
            }

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const operatorsTimeActivitySlice = createSlice({
    name: "operatorsTimeActivity",
    initialState,
    reducers: {
        reset: (state) => initialState,
        stateReset: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.isDeleted = false;
            state.message = "";
            state.errorList = [];
        },
        successReset: (state) => {
            state.isSuccess = false;
            state.isDeleted = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStorageUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getStorageUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.storageUsers = Object.values(action.payload.data)
            })
            .addCase(getStorageUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getProjects.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.isLoading = false
                state.projects = Object.values(action.payload.data)
            })
            .addCase(getProjects.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getOperatorTimeActivities.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOperatorTimeActivities.fulfilled, (state, action) => {
                state.isLoading = false
                state.activities = Object.values(action.payload.data)
                state.activitiesPagination = {current_page: action.payload.meta.current_page, last_page: action.payload.meta.last_page, per_page: action.payload.meta.per_page}
            })
            .addCase(getOperatorTimeActivities.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(createActivity.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createActivity.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(createActivity.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                state.message = action.payload
                state.errorList = action?.payload?.errors ?? []
            })
            .addCase(calculateActivity.pending, (state) => {
                state.isLoading = true
            })
            .addCase(calculateActivity.fulfilled, (state, action) => {
                state.isLoading = false
                state.currentActivities = action.payload.data
            })
            .addCase(calculateActivity.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteActivity.pending, (state, action) => {
                state.pendingDeletedActivityId = action.meta.arg
                state.isLoading = true
            })
            .addCase(deleteActivity.fulfilled, (state, action) => {
                state.isLoading = false
                state.isDeleted = true
                state.activities = state.activities.filter((activity) => {
                    return activity.id !== state.pendingDeletedActivityId;
                });
                state.pendingDeletedActivityId = 0;
            })
            .addCase(deleteActivity.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(dateHasSharedActivity.pending, (state) => {
                state.isLoading = true
            })
            .addCase(dateHasSharedActivity.fulfilled, (state, action) => {
                state.isLoading = false
                state.hasSharedActivity = action.payload.data.hasSharedActivity
            })
            .addCase(dateHasSharedActivity.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset, stateReset, successReset } = operatorsTimeActivitySlice.actions
export default operatorsTimeActivitySlice.reducer